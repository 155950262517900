@import './fonts';
@import './variables';
@import './color';
@import './elements';

@import './components/button';
@import './components/header';
@import './components/sidebar';
@import './components/page';
@import './components/page-3-columns';
@import './components/pre-block';
@import './components/search';
@import './components/scrollbar';
@import './components/settings.less';
@import './components/backdrop-overlay.less';
@import './components/header-menu.less';

@import './components/codemirror';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 1px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.color-scheme-light {
  background-color: @color-white;

  --primary-color             : @color-refinitiv-blue;
  --primary-text-color        : @color-eerie-black;
  --primary-bg-color          : @color-white;
  --header-bg-color           : @color-alabaster;
  --sidebar-bg-color          : @color-alabaster;
  --settings-bg-color         : @color-concrete;
  --separator-color           : @color-alto;
  --link-text-color           : var(--primary-color);
  --link-hover-color          : var(--link-text-color);
  --pre-bg-color              : rgba(175,184,193,0.2);
  --code-text-color           : var(--primary-text-color);
  --table-border-color        : @color-nobel;
  --notice-bg-color           : @color-concrete;
  --third-column-bg-color     : @color-alabaster;
  --button-bg-color           : @color-cornflower-blue;
  --search-bg-color           : @color-concrete;
  --text-highlight-color      : @color-black;
  --select-bg-color           : @color-white;

  --hover-bg-color            : rgba(0,0,0,0.04);
  --active-bg-color           : rgba(0,0,0,0.1);

  --code-hl-default-color     : @color-tundora;
  --code-hl-comment-color     : @color-dusty-grey;
  --code-hl-comment-bg-color  : rgba(30, 50, 80, 0.05);
  --code-hl-attr-color        : @color-grey;
  --code-hl-attribute-color   : @color-jelly-bean;
  --code-hl-keyword-color     : @color-carnation;
  --code-hl-type-color        : @color-grenadier;
  --code-hl-number-color      : @color-blue-ribbon;
  --code-hl-addition-color    : @color-green-pea;

  --scrollbar-thumb-color     : @color-nobel;
  --scrollbar-hover-color     : @color-dusty-grey;

  --live-code-preview-frame-color   : @color-whisper;
  --live-code-bg-color              : @color-alabaster;
  --live-code-line-number-bg-color  : @color-concrete;
  --live-code-active-line-bg-color  : @color-mercury;
  --live-code-line-border-bg-color  : @color-alto;
  --live-code-color                 : @color-tundora;
  --live-code-caret-color           : @color-cod-grey;
  --live-code-selection-match-color : @color-supernova;
  --live-code-bracket-active-color  : @color-chestnut-rose;
  --live-code-tooltip-active-color  : @color-white;

  --iframe-bg-color                 : @color-white;

  --mark-bg-color                   : @color-supernova;
  --box-shadow                      : 0px 0 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

.color-scheme-dark {
  background-color: @color-cod-grey;

  --primary-color             : @color-refinitiv-blue;
  --primary-text-color        : @color-silver;
  --primary-bg-color          : @color-cod-grey;
  --header-bg-color           : @color-mine-shaft;
  --sidebar-bg-color          : @color-mine-shaft;
  --settings-bg-color         : @color-emperor;
  --separator-color           : @color-tundora;
  --link-text-color           : @color-cornflower-blue;
  --link-hover-color          : var(--link-text-color);
  --pre-bg-color              : rgba(110,118,129,0.2);
  --code-text-color           : var(--primary-text-color);
  --table-border-color        : @color-grey;
  --notice-bg-color           : @color-tundora;
  --third-column-bg-color     : @color-charcoal;
  --search-bg-color           : @color-charcoal;
  --text-highlight-color      : @color-white;
  --select-bg-color           : @color-tundora;

  --hover-bg-color            : rgba(255,255,255,0.04);
  --active-bg-color           : rgba(255,255,255,0.1);

  --code-hl-default-color     : @color-dusty-grey;
  --code-hl-comment-color     : @color-grey;
  --code-hl-comment-bg-color  : rgba(90, 90, 95, 0.4);
  --code-hl-attr-color        : @color-dusty-grey;
  --code-hl-attribute-color   : @color-turquoise-blue;
  --code-hl-keyword-color     : @color-mauvelous;
  --code-hl-type-color        : @color-carnation;
  --code-hl-number-color      : @color-cornflower-blue;
  --code-hl-addition-color    : @color-persian-green;

  --scrollbar-thumb-color     : @color-dusty-grey;
  --scrollbar-hover-color     : @color-grey;

  --live-code-preview-frame-color   : @color-lights-out;
  --live-code-bg-color              : @color-mine-shaft;
  --live-code-line-number-bg-color  : @color-charcoal;
  --live-code-active-line-bg-color  : @color-emperor;
  --live-code-line-border-bg-color  : @color-tundora;
  --live-code-color                 : @color-concrete;
  --live-code-caret-color           : @color-alabaster;
  --live-code-selection-match-color : @color-supernova;
  --live-code-bracket-active-color  : @color-grenadier;
  --live-code-tooltip-active-color  : @color-cod-grey;

  --iframe-bg-color                 : @color-lights-out;

  --mark-bg-color                   : @color-supernova;
  --box-shadow                      : 0px -5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
}

body {
  font-family: 'Proxima Nova Fin', Arial, 'Helvetica Nue', Helvetica, sans-serif;
  font-size: @fs-base;
  font-weight: @font-weight-base;
  line-height: @line-height-base;
  color: var(--primary-text-color);
  margin: 0;
  padding: 0;

  &[color-scheme=light] {
    .color-scheme-light;
    @import (less) '../../../../node_modules/highlight.js/styles/atom-one-light.css';
  }
  &[color-scheme=dark] {
    .color-scheme-dark;
    @import (less) '../../../../node_modules/highlight.js/styles/atom-one-dark.css';
  }

  // The user might indicate this preference through an operating system setting
  @media (prefers-color-scheme: light) {
    background-color: @color-white;
  }
  @media (prefers-color-scheme: dark) {
    background-color: @color-cod-grey;
  }
}

@import './print';
