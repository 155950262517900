@import '../variables';

.heading(@font-size,@lead) {
  font-size: @font-size;
  margin-top: @font-size / 1.125;
  line-height: calc(unit(@lead)/unit(@font-size));
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-style: inherit;
  margin: @space-sm 0;
  font-weight: @font-weight-semi-bold;
}

h1 {
  .heading(@fs-h1,@lead-h1);
  margin-top: @space-xxl;
  margin-bottom: @space-lg;
  font-weight: @font-weight-bold;
  clear: both;
}

h2 {
  .heading(@fs-h2,@lead-h2);
  margin-top: @space-xxl;
  margin-bottom: @space-lg;
  border-bottom: @size-xxs solid;
  clear: both;
}

h3 {
  .heading(@fs-h3,@lead-h3);
  margin-top: @space-xl;
  margin-bottom: @space-md;
}

h4 {
  .heading(@fs-h4,@lead-h4);
  margin-top: @space-lg;
  font-weight: @font-weight-bold;
}

h5 {
  .heading(@fs-h5,@lead-h5);
  font-weight: @font-weight-bold;
  text-transform: uppercase;
}

h6 {
  .heading(@fs-h6,@lead-h6);
  font-weight: @font-weight-bold;
  text-transform: uppercase;
}
