::-webkit-scrollbar {
  width: 5rem;
  height: 5rem;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--hover-bg-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover-color);
}
