@import '../variables';

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  .media-desktop({
    flex-direction: row;
  });
}

.page {
  background-color: var(--sidebar-bg-color);
  flex: 1 1 auto;
  position: relative;
  min-width: 0;
  .media-desktop({
    display: flex;
  });
}

.content-container {
  background-color: var(--primary-bg-color);
  position: relative;
  display: flex;
  align-self: flex-start;
  flex: 1;
  min-height: 100%;
  min-width: 0;
  padding: 0 @space-lg;

  .media-desktop({
    border-right: 1px solid var(--separator-color);
  });

  .media-desktop-plus({
    max-width: 850;
    padding: 0 @space-xl;
  });
}

.content {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 30px;
  z-index: 1;
}

content-menu {
  display: none;
  .media-desktop-plus({
    margin-left: @space-xl;
    padding-top: @space-lg;
    display: block;
    height: calc(100vh - @header-with-border-height);
    top: @header-with-border-height;
    position: sticky;
    overflow-y: auto;
  });
  .media-desktop-extra({
    margin-left: 50rem;
    width: 400px;
  });
}

.column-wrapper {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  background-color: var(--third-column-bg-color);
  border-left: 1px solid var(--separator-color);
}
