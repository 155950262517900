@import '../variables';

pre {
  white-space: pre;
  overflow: auto;
  background: var(--pre-bg-color);
  margin: @space-md auto;
  border-radius: 3px;

  code {
    color: inherit;
    display: block;
    background: none;
    border: none;
    white-space: pre;
    padding: @space-md !important;
    font-family: 'Source Code Pro';
    font-size: @fs-md;
    background: none !important;
  }
}
