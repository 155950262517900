@import '../variables';

hr {
  border: 0;
  margin: @space-md 0;
  color: var(--separator-color);
  background-color: var(--separator-color);

  &:not([size]) {
    height: 1rem;
  }
}