a {
  outline: none;
  text-decoration: none;
  color: var(--link-text-color);

  &:hover {
    text-decoration: underline;
    color: var(--link-hover-color);
  }
}
