@import '../variables';
@import '../mixins/responsive.less';

body[mode="default+code"] {
  .pre-block {
    .media-desktop-plus({
      border: none;
    });
  }

  content-menu {
    display: none;
  }

  h1, h2 {
    clear: both;
  }

  .content-container {
    .media-desktop-plus({
      padding: 0;
      max-width: @code-content-max-width;
    })
  }

  .column-wrapper {
    .media-desktop-plus({
      display: block;
    });
  }

  .content { 
    > pre {
      &.hide {
        display: none;
      }
    }
    .media-desktop-plus({
      margin: 0;

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      > pre, > blockquote, > .pre-block {
        float: right;
        clear: right;
        margin: 0;
        width: calc(50% - 1px); // minus border left 1px
        margin-bottom: @space-md;
        padding: @space-md;
      }

      > .pre-block {
        padding: 0;
        pre {
          padding: @space-md;
        }
      }

      > h1, > h2, > h3, > h4, > h5, > h6, > p, > hr, > ul, > li, > ol, > b, > strong, > abbr, > small, > img, > code-sandbox {
        display: block;
        margin-right: 50%;
        padding: 0 @space-xl;
      }
      > table {
        width: calc(50% - 60px); // minus margin on both sides
        margin-left: @space-xl;
      }
      > aside {
        margin-right: calc(50% + @space-md);
        margin-left: @space-xl;
      }
      > em, > del, > mark, > u, > a, > label, > input {
        margin-right: 50%;
        padding: 0 @space-md;
      }
    });
  }
} 
