@import '../variables';

@toolbar-height: 35px;
@panel-min-height: 200px;
@panel-min-width: 200px;

.pandora-code-viewer {
  position: fixed;
  display: none;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: var(--primary-bg-color);

  &.active {
    display: flex;
  }
}

.pandora-toolbar {
  position: relative;
  width: 100%;
  border-bottom: 1px solid var(--separator-color);
  height: @toolbar-height;
  min-height: @toolbar-height;

  .lang-selector {
    height: 100%;
  }

  .lang-content-selector {
    .lang-item, .preview-btn {
      padding:  @space-sm;
      display: inline-flex;
      align-items: center;
      height: 100%;
    }
    .media-desktop({
      .preview-btn {
        display: none;
      }
    });
  }

  .close-btn {
    position: absolute;
    color: inherit;
    top: 0;
    right: @space-sm;
  }
}

.viewer-container {
  display: flex;
  width: 100%;
  height: calc(100% - @toolbar-height);
}

.code-panel {
  flex: 1 1 100%;
  max-width: 100%;
  background-color: var(--primary-bg-color);
  min-width: @panel-min-width;

  &.hide {
    display: none;
  }

  .media-desktop({
    flex: 1 1 50%;
    &.hide {
      display: block;
    }
  });

  .language-block {
    display: none;
    flex-direction: row;
    overflow: auto;

    &.active {
      display: block;
    }

    .code-block {
      width: 100%;
      height: 100%;
      outline: none;
      background-color: var(--live-code-bg-color);
    }
  }
}

.viewer-panel {
  display: none;
  position: relative;
  flex: 1 1 100%;
  min-width: @panel-min-width;
  flex-direction: column;

  .media-desktop({
    display: flex;
    flex: 1 1 50%;
  });

  &.active {
    display: flex;
  }

  iframe {
    background: var(--live-code-preview-frame-color);
    width: 100%;
    height: 100%;
    border: 1rem solid var(--separator-color);
  }
}

.iframe-panel {
  display: flex;
  flex: 1 1 50%;
  min-height: @panel-min-height;
}

.iframe-panel > code-sandbox {
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
}

.console-panel {
  display: flex;
  flex: 1 1 50%;
  min-height: @panel-min-height;
}

panel-dragger[vertical] {
  display: block;
}

panel-dragger[horizontal] {
  .media-desktop({
    display: block;
  })
}

code-sandbox {
  display: block;
  pre { 
    display: none;
  }
}

.content > code-sandbox {
  padding: @space-lg;
  margin-bottom: @space-lg;
  border: 1px solid var(--separator-color);
  background-color: var(--iframe-bg-color);
}

.demo-block {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-right: 0;

  .demo-preview {
    width: 100%;
    padding: 0 15px;

    .media-desktop({
      width: 50%;
      margin-right: 50%;
      padding: 0 45px;
    });
  }

  .media-desktop({
    display: block;
  });
}

.cm-editor {
  height: 100%;

  .cm-scroller {
    font-family: 'Source Code Pro' !important;
    font-size: @fs-md !important;
  }

  .cm-gutters {
    background-color: var(--live-code-line-number-bg-color);
    border-right: 1px solid var(--live-code-line-border-bg-color);

    .cm-activeLineGutter {
      background-color: var(--live-code-active-line-bg-color);
    }
  }

  .cm-foldGutter {
    font-size: @fs-md;

    span[title="Fold line"] {
      line-height: .75;
    }
    span[title="Unfold line"] {
      line-height: 1.15;
    }
  }

  .cm-content {
    .cm-activeLine {
      background-color: var(--live-code-active-line-bg-color);
    }
  }

  .cm-selectionBackground {
    background: var(--live-code-active-line-bg-color) !important;
  }
  .cm-selectionMatch {
    color: var(--link-text-color);
    background-color: var(--live-code-selection-match-color) !important;
  }
  .cm-matchingBracket {
    color: var(--live-code-bracket-active-color);
  }

  .cm-tooltip {
    border: 1px solid var(--live-code-active-line-bg-color);
    background-color: var(--live-code-bg-color);

    &.cm-tooltip-autocomplete {
      > ul {
        font-family: 'Source Code Pro';
        font-size: @fs-md;

        > li[aria-selected] {
          background-color: var(--code-hl-number-color);
          color: var(--live-code-tooltip-active-color);
        }
      }
    }
  }

  .cm-line {
    color: var(--live-code-color);
  }

  .cm-nonmatchingBracket, .cm-matchingBracket {
    color: var(--code-hl-type-color);
  }
}
.cm-cursor {
  border-left: 1.2px solid var(--live-code-caret-color) !important;
}

// HTML Color modified
.html-code {
  .cm-editor {
    .ͼh {
      color: var(--code-hl-number-color);

      .cm-selectionMatch {
        color: var(--code-hl-number-color);
      }
    }
    .ͼd {
      color: var(--code-hl-type-color);
    }
  }
}

// CSS Color modified
.css-code {
  .cm-editor {
    .ͼh {
      color: var(--code-hl-number-color);
    }

    .ͼi, .ͼd {
      color: var(--code-hl-type-color);
    }
    .ͼb, .ͼc {
      color: var(--code-hl-number-color);
    }
    .ͼa {
      color: var(--code-hl-attribute-color);
    }
  }
}

// JS Color modified
.js-code, .javascript-code {
  .cm-editor {
    .ͼa {
      color: var(--code-hl-keyword-color);
    }
    .ͼb {
      color: var(--code-hl-attribute-color);
    }
    .ͼc {
      color: var(--code-hl-attribute-color);
    }
    .ͼf {
      color: var(--code-hl-number-color);
    }
    .ͼd {
      color: var(--code-hl-type-color);
    }
  }
}
