@import '../variables';
@import '../mixins/responsive.less';

.sidebar {
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  visibility: hidden;
  flex: 1 0 auto;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: @sidebar-width;
  min-width: @sidebar-width;
  transition: all 200ms ease-in-out;
  transform: translateX(-@sidebar-width);
  background-color: var(--sidebar-bg-color);

  &.is-visible {
    z-index: 1100;
    transform: translateX(0);
    visibility: visible;
  }

  .media-desktop({
    position: sticky;
    top: @header-with-border-height;
    height: calc(100vh - @header-with-border-height);
    visibility: visible;
    display: flex;
    flex-direction: column;
    transition: none;
    flex: 0 0 auto;
    transform: translateX(0);
    border-right: 1px solid var(--separator-color);
    width: calc((100% - @container-max-width) / 2 + @sidebar-width);
    padding-left: calc((100% - @container-max-width) / 2);
  });
}

.sidebar-header {
  display: flex;
  align-items: center;
  height: @header-height;
  min-height: @header-height;
  background-color: var(--header-bg-color);

  .site-header {
    margin-left: @space-lg;
  }

  .header-title {
    max-width: 200px;
    margin-left: @space-md;
    display: block;
  }

  .media-desktop({
    display: none;
  });
}

.arrow-icon {
  width: 10px;
  height: 10px;
  border-width: 2px 2px 0 0;
  border-style: solid;
  border-color: var(--primary-text-color);
  color: var(--primary-text-color);

  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: -2px;
    height: 2px;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg);
    width: 14px;
    transform-origin: right top;
  }
}

pandora-nav {
  padding-left: @space-xl;
}

pandora-nav-item[type="menu"] {
  .media-desktop-plus({
    display: none;
  });
}
