@import '../variables';
@import '../mixins/responsive.less';
@import '../color';

@logo-height: 30px;
@header-menu-max-width: 850px;

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: var(--header-bg-color);
  border-bottom: 1px solid var(--separator-color);
}

.header-container {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  margin: 0 auto;
  max-width: @container-max-width;
  height: @header-height;
  padding: 0 @space-xs;

  &.is-visible {
    opacity: 1;
  }

  .media-desktop({
    padding: 0 @space-xl;
  });
}

.header-left {
  position: relative;
  display: flex;
  flex:auto;
  align-items: center;
}

.mobile-menu {
  flex-direction: column;
  flex-shrink: 0;

  span {
    width: 15px;
    height: 2px;
    margin: 2px 0;
    background-color: var(--primary-text-color);
  }

  .media-desktop({
    display: none;
  });
}

.site-header {
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  text-decoration: none;
  align-items: center;

  &:hover {
    text-decoration: none;
  }

  .media-desktop({
    margin-left: 0;
  });

  .header-title {
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 500px;
    font-weight: 300;
    font-size: @fs-h5;
    color: var(--primary-text-color);
    margin-top: 0;
    margin-bottom: 0;
    margin-left: @space-xs;

    &:hover {
      color: var(--text-highlight-color);
    }

    .media-desktop({
      display: block;
      margin-left: @space-md;
    });
  }

  .header-logo {
    display: block;
    width: auto;
    height: @logo-height;
    min-height: @logo-height;
    margin-left: @space-xs;

    .media-desktop({
      margin-left: 0;
    });
  }
}

body[color-scheme="dark"] {
  .header-logo.invert-color {
    filter: brightness(0) invert(1);
  }
}

.header-menu {
  display: none;
  font-size: @fs-base;
  height: 100%;
  margin-right: @space-md;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 400;
    margin: 0 @space-md;
    color: var(--primary-text-color);

    &:hover {
      color: var(--text-highlight-color);
    }
  }

  .dropdown {
    a {
      margin: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }

  .media-desktop-plus({
    display: flex;
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    padding: 0;
    justify-content: flex-end;
  });
}

.header-right {
  display: flex;
}
