@import '../variables';

table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;
  line-height: 1.3;
  border-color: var(--separator-color);
  font-size: @fs-md;

  :not(:last-child) {
    > :last-child > * {
      border-bottom-color: var(--table-border-color);
    }
  }

  * > tr > th, td {
    border-bottom-width: 1rem;
    padding: @space-md;
  }

  * tr > th {
    padding-bottom: @space-sm;
  }
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

thead {
  width: 100%;
  > thead {
    vertical-align: bottom;
  }
}

tbody {
  width: 100%;
}

th {
  text-align: left;
  font-weight: @font-weight-semi-bold;
  opacity: 0.7;

  &[align=center] {
    text-align: center;
  }
  &[align=right] {
    text-align: right;
  }
}
