@font-face {
  font-family: Proxima Nova Fin;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-regular.woff2") format("woff2"), url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-regular.woff") format("woff");
}

@font-face {
  font-family: Proxima Nova Fin;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-semibold.woff2") format("woff2"), url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-semibold.woff") format("woff");
}

@font-face {
  font-family: Proxima Nova Fin;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-bold.woff2") format("woff2"), url("https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-bold.woff") format("woff");
}

@font-face {
  font-family: Source Code Pro;
  src: url("sourcecodepro-bold-webfont.a1388491.woff2") format("woff2"), url("sourcecodepro-bold-webfont.19c7cd25.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("sourcecodepro-light-webfont.43bccd8e.woff2") format("woff2"), url("sourcecodepro-light-webfont.c9801a99.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Source Code Pro;
  src: url("sourcecodepro-regular-webfont.8e5e2e56.woff2") format("woff2"), url("sourcecodepro-regular-webfont.93b6e55d.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

a {
  color: var(--link-text-color);
  outline: none;
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
  text-decoration: underline;
}

abbr[title] {
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  margin: 0;
}

[type="checkbox"] {
  padding: 0;
}

[type="checkbox"]:not(:checked), [type="checkbox"]:checked {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

[type="checkbox"] + span {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 20rem;
  padding-left: 25rem;
  font-size: 16rem;
  line-height: 20rem;
  display: inline-block;
  position: relative;
}

[type="checkbox"] + span:before, [type="checkbox"] + span:after {
  content: "";
  z-index: 0;
  position: absolute;
  left: 0;
}

[type="checkbox"] + span:after {
  z-index: 0;
  border: 1rem solid var(--primary-text-color);
  background-color: #0000;
  width: 16rem;
  height: 16rem;
  top: 1rem;
  left: 2rem;
}

[type="checkbox"] + span:before {
  z-index: 1;
  border: 2rem solid var(--primary-text-color);
  border-radius: 1rem;
  width: 0;
  height: 0;
  top: 2rem;
  left: 8rem;
  transform: rotateZ(37deg);
}

[type="checkbox"]:not(:checked) + span:before {
  border: 3rem solid #0000;
}

[type="checkbox"]:checked + span:before {
  border-top: 2rem solid #0000;
  border-left: 2rem solid #0000;
  border-right: 2rem solid var(--primary-text-color);
  border-bottom: 2rem solid var(--primary-text-color);
  width: 5rem;
  height: 10rem;
}

:not(pre) code {
  background: var(--pre-bg-color);
  box-sizing: border-box;
  color: var(--code-text-color);
  border-radius: 5px;
  padding: .2em .4em;
  font-size: 80%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: inherit;
  font-style: inherit;
  margin: 12rem 0;
  font-weight: 500;
}

h1 {
  margin-top: 48rem / 1.125;
  clear: both;
  margin-top: 60rem;
  margin-bottom: 24rem;
  font-size: 48rem;
  font-weight: 600;
  line-height: 1.04167;
}

h2 {
  margin-top: 28rem / 1.125;
  clear: both;
  border-bottom: 4rem solid;
  margin-top: 60rem;
  margin-bottom: 24rem;
  font-size: 28rem;
  line-height: 1.5;
}

h3 {
  margin-top: 24rem / 1.125;
  margin-top: 40rem;
  margin-bottom: 16rem;
  font-size: 24rem;
  line-height: 1.41667;
}

h4 {
  margin-top: 20rem / 1.125;
  margin-top: 24rem;
  font-size: 20rem;
  font-weight: 600;
  line-height: 1.5;
}

h5 {
  margin-top: 18rem / 1.125;
  text-transform: uppercase;
  font-size: 18rem;
  font-weight: 600;
  line-height: 1.33333;
}

h6 {
  margin-top: 16rem / 1.125;
  text-transform: uppercase;
  font-size: 16rem;
  font-weight: 600;
  line-height: 1.375;
}

hr {
  color: var(--separator-color);
  background-color: var(--separator-color);
  border: 0;
  margin: 16rem 0;
}

hr:not([size]) {
  height: 1rem;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

mark {
  background-color: var(--mark-bg-color);
  padding: 2px;
}

p {
  margin-top: 0;
  margin-bottom: 12rem;
}

pre {
  white-space: pre;
  background: var(--pre-bg-color);
  border-radius: 3px;
  margin: 16rem auto;
  overflow: auto;
}

pre code {
  color: inherit;
  white-space: pre;
  background: none;
  border: none;
  font-family: Source Code Pro;
  font-size: 14rem;
  display: block;
  background: none !important;
  padding: 16rem !important;
}

small {
  font-size: 12rem;
}

strong {
  font-weight: bolder;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
  border-color: var(--separator-color);
  width: 100%;
  font-size: 14rem;
  line-height: 1.3;
}

table :not(:last-child) > :last-child > * {
  border-bottom-color: var(--table-border-color);
}

table * > tr > th, table td {
  border-bottom-width: 1rem;
  padding: 16rem;
}

table * tr > th {
  padding-bottom: 12rem;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

thead {
  width: 100%;
}

thead > thead {
  vertical-align: bottom;
}

tbody {
  width: 100%;
}

th {
  text-align: left;
  opacity: .7;
  font-weight: 500;
}

th[align="center"] {
  text-align: center;
}

th[align="right"] {
  text-align: right;
}

aside {
  --notice-notice-color: #334bff;
  --notice-warning-color: #ffc800;
  --notice-important-color: #009ea1;
  --notice-success-color: #39c46e;
  --notice-fail-color: #b63243;
  background-color: var(--notice-bg-color);
  border-left: 4px solid;
  border-radius: 3px;
  margin: 30px 0;
  padding: 15px 15px 15px 40px;
  display: block;
  position: relative;
}

aside:before {
  width: 24px;
  height: 24px;
  display: block;
  position: absolute;
  top: 15px;
  left: 8px;
}

aside.info {
  border-color: var(--notice-notice-color);
}

aside.info:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M8%202.5a5.5%205.5%200%20100%2011%205.5%205.5%200%20000-11z%22%20stroke%3D%22%23334BFF%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M8%207v4-4zM8%205v1-1z%22%20stroke%3D%22%23334BFF%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

aside.warning {
  border-color: var(--notice-warning-color);
}

aside.warning:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Cg%20stroke%3D%22%23FFC800%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M8%203.044L2.5%2013.127v.373h11v-.373L8%203.044zM8%206.5V10M8%2011v1%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

aside.important {
  border-color: var(--notice-important-color);
}

aside.important:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M8%202.5a5.5%205.5%200%20100%2011%205.5%205.5%200%20000-11z%22%20stroke%3D%22%23009EA1%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M8%209V5v4zM8%2011v-1%201z%22%20stroke%3D%22%23009EA1%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

aside.success {
  border-color: var(--notice-success-color);
}

aside.success:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Cg%20transform%3D%22translate(2%202)%22%20stroke%3D%22%2339C46E%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M9%204L4.5%208.5l-2-2%22%3E%3C%2Fpath%3E%3Ccircle%20cx%3D%226%22%20cy%3D%226%22%20r%3D%225.5%22%3E%3C%2Fcircle%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

aside.fail {
  border-color: var(--notice-fail-color);
}

aside.fail:before {
  content: url("data:image/svg+xml,%3Csvg%20width%3D%2225%22%20height%3D%2225%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20focusable%3D%22false%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20d%3D%22M8%202.5a5.5%205.5%200%20100%2011%205.5%205.5%200%20000-11z%22%20stroke%3D%22%23B63243%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M5.455%2010.6l5-5.134-5%205.135z%22%20stroke%3D%22%23B63243%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M5.455%205.466l5.17%205.135-5.17-5.135z%22%20stroke%3D%22%23B63243%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

aside p:last-child {
  margin-bottom: 0;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 16rem;
  padding-left: 16rem;
  list-style-position: inside;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

iframe {
  background-color: var(--iframe-bg-color);
  border: none;
  width: 100%;
  display: block;
}

.action-button {
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  line-height: 1px;
  transition: background-color .5s;
  display: flex;
}

.action-button:hover {
  background-color: var(--hover-bg-color);
}

.action-button:active {
  background-color: var(--active-bg-color);
}

header {
  z-index: 1000;
  background-color: var(--header-bg-color);
  border-bottom: 1px solid var(--separator-color);
  position: sticky;
  top: 0;
}

.header-container {
  opacity: 0;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  height: 65px;
  margin: 0 auto;
  padding: 0 8rem;
  display: flex;
  position: relative;
}

.header-container.is-visible {
  opacity: 1;
}

@media (min-width: 1024px) {
  .header-container {
    padding: 0 40rem;
  }
}

.header-left {
  flex: auto;
  align-items: center;
  display: flex;
  position: relative;
}

.mobile-menu {
  flex-direction: column;
  flex-shrink: 0;
}

.mobile-menu span {
  background-color: var(--primary-text-color);
  width: 15px;
  height: 2px;
  margin: 2px 0;
}

@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

.site-header {
  flex-wrap: wrap;
  flex: auto;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.site-header:hover {
  text-decoration: none;
}

@media (min-width: 1024px) {
  .site-header {
    margin-left: 0;
  }
}

.site-header .header-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-text-color);
  max-width: 500px;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8rem;
  font-size: 18rem;
  font-weight: 300;
  display: none;
  overflow: hidden;
}

.site-header .header-title:hover {
  color: var(--text-highlight-color);
}

@media (min-width: 1024px) {
  .site-header .header-title {
    margin-left: 16rem;
    display: block;
  }
}

.site-header .header-logo {
  width: auto;
  height: 30px;
  min-height: 30px;
  margin-left: 8rem;
  display: block;
}

@media (min-width: 1024px) {
  .site-header .header-logo {
    margin-left: 0;
  }
}

body[color-scheme="dark"] .header-logo.invert-color {
  filter: brightness(0) invert();
}

.header-menu {
  height: 100%;
  margin-right: 16rem;
  font-size: 16rem;
  display: none;
}

.header-menu a {
  color: var(--primary-text-color);
  align-items: center;
  margin: 0 16rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}

.header-menu a:hover {
  color: var(--text-highlight-color);
}

.header-menu .dropdown a {
  margin: 0;
}

.header-menu .dropdown:first-child {
  margin-left: 0;
}

@media (min-width: 1200px) {
  .header-menu {
    flex: auto;
    justify-content: flex-end;
    padding: 0;
    display: flex;
    position: relative;
    overflow: hidden;
  }
}

.header-right {
  display: flex;
}

.sidebar {
  visibility: hidden;
  z-index: 1000;
  background-color: var(--sidebar-bg-color);
  flex-flow: column;
  flex: 1 0 auto;
  width: 280px;
  min-width: 280px;
  height: 100%;
  transition: all .2s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-280px);
}

.sidebar.is-visible {
  z-index: 1100;
  visibility: visible;
  transform: translateX(0);
}

@media (min-width: 1024px) {
  .sidebar {
    visibility: visible;
    border-right: 1px solid var(--separator-color);
    flex-direction: column;
    flex: none;
    width: calc(50% - 520px);
    height: calc(100vh - 66px);
    padding-left: calc(50% - 800px);
    transition: none;
    display: flex;
    position: sticky;
    top: 66px;
    transform: translateX(0);
  }
}

.sidebar-header {
  background-color: var(--header-bg-color);
  align-items: center;
  height: 65px;
  min-height: 65px;
  display: flex;
}

.sidebar-header .site-header {
  margin-left: 24rem;
}

.sidebar-header .header-title {
  max-width: 200px;
  margin-left: 16rem;
  display: block;
}

@media (min-width: 1024px) {
  .sidebar-header {
    display: none;
  }
}

.arrow-icon {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: var(--primary-text-color);
  color: var(--primary-text-color);
  width: 10px;
  height: 10px;
}

.arrow-icon:before {
  content: "";
  transform-origin: 100% 0;
  width: 14px;
  height: 2px;
  position: absolute;
  top: -2px;
  right: 0;
  transform: rotate(-45deg);
  box-shadow: inset 0 0 0 32px;
}

pandora-nav {
  padding-left: 40rem;
}

@media (min-width: 1200px) {
  pandora-nav-item[type="menu"] {
    display: none;
  }
}

main {
  flex-direction: column;
  flex: 1;
  display: flex;
}

@media (min-width: 1024px) {
  main {
    flex-direction: row;
  }
}

.page {
  background-color: var(--sidebar-bg-color);
  flex: auto;
  min-width: 0;
  position: relative;
}

@media (min-width: 1024px) {
  .page {
    display: flex;
  }
}

.content-container {
  background-color: var(--primary-bg-color);
  flex: 1;
  align-self: flex-start;
  min-width: 0;
  min-height: 100%;
  padding: 0 24rem;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .content-container {
    border-right: 1px solid var(--separator-color);
  }
}

@media (min-width: 1200px) {
  .content-container {
    max-width: 850px;
    padding: 0 40rem;
  }
}

.content {
  z-index: 1;
  width: 100%;
  padding-bottom: 30px;
  display: block;
  position: relative;
}

content-menu {
  display: none;
}

@media (min-width: 1200px) {
  content-menu {
    height: calc(100vh - 66px);
    margin-left: 40rem;
    padding-top: 24rem;
    display: block;
    position: sticky;
    top: 66px;
    overflow-y: auto;
  }
}

@media (min-width: 1600px) {
  content-menu {
    width: 400px;
    margin-left: 50rem;
  }
}

.column-wrapper {
  background-color: var(--third-column-bg-color);
  border-left: 1px solid var(--separator-color);
  width: 50%;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

@media (min-width: 1200px) {
  body[mode="default+code"] .pre-block {
    border: none;
  }
}

body[mode="default+code"] content-menu {
  display: none;
}

body[mode="default+code"] h1, body[mode="default+code"] h2 {
  clear: both;
}

@media (min-width: 1200px) {
  body[mode="default+code"] .content-container {
    max-width: 1200px;
    padding: 0;
  }

  body[mode="default+code"] .column-wrapper {
    display: block;
  }
}

body[mode="default+code"] .content > pre.hide {
  display: none;
}

@media (min-width: 1200px) {
  body[mode="default+code"] .content {
    margin: 0;
  }

  body[mode="default+code"] .content:after {
    content: "";
    clear: both;
    display: block;
  }

  body[mode="default+code"] .content > pre, body[mode="default+code"] .content > blockquote, body[mode="default+code"] .content > .pre-block {
    float: right;
    clear: right;
    width: calc(50% - 1px);
    margin: 0 0 16rem;
    padding: 16rem;
  }

  body[mode="default+code"] .content > .pre-block {
    padding: 0;
  }

  body[mode="default+code"] .content > .pre-block pre {
    padding: 16rem;
  }

  body[mode="default+code"] .content > h1, body[mode="default+code"] .content > h2, body[mode="default+code"] .content > h3, body[mode="default+code"] .content > h4, body[mode="default+code"] .content > h5, body[mode="default+code"] .content > h6, body[mode="default+code"] .content > p, body[mode="default+code"] .content > hr, body[mode="default+code"] .content > ul, body[mode="default+code"] .content > li, body[mode="default+code"] .content > ol, body[mode="default+code"] .content > b, body[mode="default+code"] .content > strong, body[mode="default+code"] .content > abbr, body[mode="default+code"] .content > small, body[mode="default+code"] .content > img, body[mode="default+code"] .content > code-sandbox {
    margin-right: 50%;
    padding: 0 40rem;
    display: block;
  }

  body[mode="default+code"] .content > table {
    width: calc(50% - 60px);
    margin-left: 40rem;
  }

  body[mode="default+code"] .content > aside {
    margin-left: 40rem;
    margin-right: calc(50% + 16rem);
  }

  body[mode="default+code"] .content > em, body[mode="default+code"] .content > del, body[mode="default+code"] .content > mark, body[mode="default+code"] .content > u, body[mode="default+code"] .content > a, body[mode="default+code"] .content > label, body[mode="default+code"] .content > input {
    margin-right: 50%;
    padding: 0 16rem;
  }
}

.lang-selector {
  background-color: var(--primary-bg-color);
  width: 100%;
}

.lang-selector .lang-item, .lang-selector .preview-btn {
  cursor: pointer;
  color: var(--primary-text-color);
  padding: 12rem 16rem;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;
}

.lang-selector .lang-item:hover, .lang-selector .preview-btn:hover {
  opacity: .8;
}

.lang-global-selector {
  z-index: 1;
  width: 1200px / 2;
  position: fixed;
}

.lang-global-selector .lang-item.active {
  background-color: var(--third-column-bg-color);
}

.lang-content-selector .lang-item.active {
  background-color: var(--pre-bg-color);
}

@media (min-width: 1024px) {
  .lang-content-selector .lang-item.desktop-active {
    background-color: var(--pre-bg-color);
  }
}

.pre-block {
  border: 1px solid var(--separator-color);
  clear: none;
  border-radius: 3px;
  margin-bottom: 16rem;
}

.pre-block pre {
  margin: 0;
  display: none;
}

.pre-block pre.active {
  display: block;
}

.search-container {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  background: #fff;
  height: 65px;
  transition: opacity .1s cubic-bezier(.4, 0, .2, 1) .1s;
  display: flex;
  position: fixed;
  inset: 0;
}

.search-container .search-input {
  color: #212121;
  background-color: #0000;
  border: none;
  outline: none;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 0 100px 0 24rem;
  font-size: 16rem;
  transition: top .3s cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
  top: 16px;
}

@media (min-width: 1024px) {
  .search-container .search-input {
    padding-left: 50px;
    padding-right: 150px;
  }
}

.search-container.is-visible {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.search-container.is-visible .search-input {
  top: 0;
}

@media (min-width: 1600px) {
  .search-container {
    padding: 0 calc(50% - 800px);
  }
}

.search-button {
  color: var(--primary-text-color);
  cursor: pointer;
  z-index: 2;
}

.search-button.close-button {
  color: #212121;
}

.search-button.close-button:after {
  content: "×";
  font-family: serif;
  font-size: 28rem;
  font-weight: 300;
}

.search-button.close-button svg {
  display: none;
}

.search-button svg {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.results-container {
  z-index: 2;
  background-color: var(--primary-bg-color);
  height: calc(100% - 65px);
  padding: 0 16rem 24rem;
  transition: transform .2s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: auto;
  transform: translateY(-100%);
}

.results-container a {
  color: var(--primary-text-color);
}

.results-container p {
  word-break: break-all;
}

.results-container mark {
  color: var(--text-highlight-color);
  background-color: #0000;
  font-weight: 500;
}

.results-container.is-visible {
  transform: translateY(65px);
}

@media (min-width: 1024px) {
  .results-container {
    margin-left: 280px;
    padding: 0 40rem;
  }
}

@media (min-width: 1600px) {
  .results-container {
    margin-left: calc(50% - 520px);
  }
}

.item-container {
  cursor: pointer;
  margin-bottom: 40rem;
  text-decoration: none;
  display: block;
}

.item-container h4 {
  padding-left: 12rem;
  position: relative;
}

.item-container h4:before {
  content: "";
  background-color: var(--code-hl-number-color);
  width: 2px;
  height: 100%;
  position: absolute;
  left: 0;
}

.item-container mark {
  padding: 0;
}

.item-container:hover {
  opacity: .9;
}

.results-placeholder {
  text-align: center;
  opacity: .8;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  height: 100%;
  display: none;
}

.results-placeholder img {
  width: 150px;
  height: auto;
}

.results-placeholder.is-visible {
  display: flex;
}

.results-body {
  max-width: 1200px;
}

.results-errorMsg {
  word-break: break-all;
}

::-webkit-scrollbar {
  width: 5rem;
  height: 5rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px var(--hover-bg-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover-color);
}

.settings-content {
  background-color: var(--sidebar-bg-color);
  z-index: 1100;
  visibility: hidden;
  width: 350px;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(350px);
}

@media (min-width: 1024px) {
  .settings-content {
    width: 400px;
    transform: translateX(400px);
  }
}

.settings-content.is-visible {
  visibility: visible;
  transform: translateX(0);
}

.settings-header {
  border-bottom: 1px solid var(--separator-color);
  justify-content: flex-end;
  align-items: center;
  height: 65px;
  min-height: 66px;
  padding-right: 8rem;
  display: flex;
}

@media (min-width: 1024px) {
  .settings-header {
    padding-right: 40rem;
  }
}

.settings-header .right-arrow {
  transform: rotate(45deg);
}

.settings-button {
  color: var(--primary-text-color);
}

.settings-button:after {
  content: "⋮";
  font-size: 26px;
  font-weight: bold;
}

.settings-body {
  padding: 40rem 16rem;
}

@media (min-width: 1024px) {
  .settings-body {
    padding: 40rem;
  }
}

.settings-item {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40rem;
  display: flex;
}

.theme-toggle {
  cursor: pointer;
  width: auto;
  height: auto;
}

.theme-toggle input {
  display: none;
}

.theme-toggle input[type="checkbox"]:checked + .switch {
  background-color: #404040;
}

.theme-toggle input[type="checkbox"]:checked + .switch:before {
  background-color: #1a1a1a;
  transform: translateX(20px);
}

.theme-toggle .switch {
  vertical-align: middle;
  background: #d9d9d9;
  border-radius: 16px;
  width: 44px;
  height: 24px;
  transition: background .25s;
  display: inline-block;
  position: relative;
}

.theme-toggle .switch:before, .theme-toggle .switch:after {
  content: "";
}

.theme-toggle .switch:before {
  background-color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: transform .25s;
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  box-shadow: 1px 1px 5px #0003;
}

.backdrop-overlay {
  visibility: hidden;
  z-index: 1100;
  background-color: #0000004d;
  transition: opacity .2s ease-out, visibility 0s linear .2s;
  display: none;
  position: fixed;
  inset: 0;
}

.backdrop-overlay.is-visible, .backdrop-overlay.desktop-visible {
  visibility: visible;
  display: block;
}

.backdrop-overlay.desktop-visible {
  z-index: 1100;
}

@media (min-width: 1024px) {
  .backdrop-overlay, .backdrop-overlay.is-visible {
    display: none;
  }
}

.dropdown {
  margin: 0 16rem;
  position: relative;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropdown-btn {
  color: var(--text-highlight-color);
}

.dropdown-btn {
  font-family: inherit;
  font-size: inherit;
  color: var(--primary-text-color);
  align-items: center;
  height: 100%;
  font-weight: 500;
  display: flex;
}

.dropdown-arrow {
  cursor: pointer;
  border: 2px solid;
  border-width: 0 2px 2px 0;
  margin-bottom: 2px;
  margin-left: 10px;
  padding: 2.5px;
  transform: rotate(45deg);
}

.dropdown-content {
  background-color: var(--sidebar-bg-color);
  box-shadow: var(--box-shadow);
  min-width: 160px;
  display: none;
  position: fixed;
  top: 50px;
}

.dropdown-content a {
  text-align: left;
  padding: 12rem 16rem;
}

.pandora-code-viewer {
  z-index: 9999;
  background-color: var(--primary-bg-color);
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.pandora-code-viewer.active {
  display: flex;
}

.pandora-toolbar {
  border-bottom: 1px solid var(--separator-color);
  width: 100%;
  height: 35px;
  min-height: 35px;
  position: relative;
}

.pandora-toolbar .lang-selector {
  height: 100%;
}

.pandora-toolbar .lang-content-selector .lang-item, .pandora-toolbar .lang-content-selector .preview-btn {
  align-items: center;
  height: 100%;
  padding: 12rem;
  display: inline-flex;
}

@media (min-width: 1024px) {
  .pandora-toolbar .lang-content-selector .preview-btn {
    display: none;
  }
}

.pandora-toolbar .close-btn {
  color: inherit;
  position: absolute;
  top: 0;
  right: 12rem;
}

.viewer-container {
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
}

.code-panel {
  background-color: var(--primary-bg-color);
  flex: 100%;
  min-width: 200px;
  max-width: 100%;
}

.code-panel.hide {
  display: none;
}

@media (min-width: 1024px) {
  .code-panel {
    flex: 50%;
  }

  .code-panel.hide {
    display: block;
  }
}

.code-panel .language-block {
  flex-direction: row;
  display: none;
  overflow: auto;
}

.code-panel .language-block.active {
  display: block;
}

.code-panel .language-block .code-block {
  background-color: var(--live-code-bg-color);
  outline: none;
  width: 100%;
  height: 100%;
}

.viewer-panel {
  flex-direction: column;
  flex: 100%;
  min-width: 200px;
  display: none;
  position: relative;
}

@media (min-width: 1024px) {
  .viewer-panel {
    flex: 50%;
    display: flex;
  }
}

.viewer-panel.active {
  display: flex;
}

.viewer-panel iframe {
  background: var(--live-code-preview-frame-color);
  border: 1rem solid var(--separator-color);
  width: 100%;
  height: 100%;
}

.iframe-panel {
  flex: 50%;
  min-height: 200px;
  display: flex;
}

.iframe-panel > code-sandbox {
  width: 100%;
  min-width: 0;
  height: 100%;
  min-height: 0;
}

.console-panel {
  flex: 50%;
  min-height: 200px;
  display: flex;
}

panel-dragger[vertical] {
  display: block;
}

@media (min-width: 1024px) {
  panel-dragger[horizontal] {
    display: block;
  }
}

code-sandbox {
  display: block;
}

code-sandbox pre {
  display: none;
}

.content > code-sandbox {
  border: 1px solid var(--separator-color);
  background-color: var(--iframe-bg-color);
  margin-bottom: 24rem;
  padding: 24rem;
}

.demo-block {
  flex-direction: column-reverse;
  width: 100%;
  margin-right: 0;
  display: flex;
}

.demo-block .demo-preview {
  width: 100%;
  padding: 0 15px;
}

@media (min-width: 1024px) {
  .demo-block .demo-preview {
    width: 50%;
    margin-right: 50%;
    padding: 0 45px;
  }

  .demo-block {
    display: block;
  }
}

.cm-editor {
  height: 100%;
}

.cm-editor .cm-scroller {
  font-family: Source Code Pro !important;
  font-size: 14rem !important;
}

.cm-editor .cm-gutters {
  background-color: var(--live-code-line-number-bg-color);
  border-right: 1px solid var(--live-code-line-border-bg-color);
}

.cm-editor .cm-gutters .cm-activeLineGutter {
  background-color: var(--live-code-active-line-bg-color);
}

.cm-editor .cm-foldGutter {
  font-size: 14rem;
}

.cm-editor .cm-foldGutter span[title="Fold line"] {
  line-height: .75;
}

.cm-editor .cm-foldGutter span[title="Unfold line"] {
  line-height: 1.15;
}

.cm-editor .cm-content .cm-activeLine {
  background-color: var(--live-code-active-line-bg-color);
}

.cm-editor .cm-selectionBackground {
  background: var(--live-code-active-line-bg-color) !important;
}

.cm-editor .cm-selectionMatch {
  color: var(--link-text-color);
  background-color: var(--live-code-selection-match-color) !important;
}

.cm-editor .cm-matchingBracket {
  color: var(--live-code-bracket-active-color);
}

.cm-editor .cm-tooltip {
  border: 1px solid var(--live-code-active-line-bg-color);
  background-color: var(--live-code-bg-color);
}

.cm-editor .cm-tooltip.cm-tooltip-autocomplete > ul {
  font-family: Source Code Pro;
  font-size: 14rem;
}

.cm-editor .cm-tooltip.cm-tooltip-autocomplete > ul > li[aria-selected] {
  background-color: var(--code-hl-number-color);
  color: var(--live-code-tooltip-active-color);
}

.cm-editor .cm-line {
  color: var(--live-code-color);
}

.cm-editor .cm-nonmatchingBracket, .cm-editor .cm-matchingBracket {
  color: var(--code-hl-type-color);
}

.cm-cursor {
  border-left: 1.2px solid var(--live-code-caret-color) !important;
}

.html-code .cm-editor .ͼh, .html-code .cm-editor .ͼh .cm-selectionMatch {
  color: var(--code-hl-number-color);
}

.html-code .cm-editor .ͼd {
  color: var(--code-hl-type-color);
}

.css-code .cm-editor .ͼh {
  color: var(--code-hl-number-color);
}

.css-code .cm-editor .ͼi, .css-code .cm-editor .ͼd {
  color: var(--code-hl-type-color);
}

.css-code .cm-editor .ͼb, .css-code .cm-editor .ͼc {
  color: var(--code-hl-number-color);
}

.css-code .cm-editor .ͼa {
  color: var(--code-hl-attribute-color);
}

.js-code .cm-editor .ͼa, .javascript-code .cm-editor .ͼa {
  color: var(--code-hl-keyword-color);
}

.js-code .cm-editor .ͼb, .javascript-code .cm-editor .ͼb, .js-code .cm-editor .ͼc, .javascript-code .cm-editor .ͼc {
  color: var(--code-hl-attribute-color);
}

.js-code .cm-editor .ͼf, .javascript-code .cm-editor .ͼf {
  color: var(--code-hl-number-color);
}

.js-code .cm-editor .ͼd, .javascript-code .cm-editor .ͼd {
  color: var(--code-hl-type-color);
}

*, :before, :after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 1px;
}

.container {
  flex-direction: column;
  height: 100%;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
}

.color-scheme-light {
  --primary-color: #001eff;
  --primary-text-color: #212121;
  --primary-bg-color: #fff;
  --header-bg-color: #fafafa;
  --sidebar-bg-color: #fafafa;
  --settings-bg-color: #f2f2f2;
  --separator-color: #d9d9d9;
  --link-text-color: var(--primary-color);
  --link-hover-color: var(--link-text-color);
  --pre-bg-color: #afb8c133;
  --code-text-color: var(--primary-text-color);
  --table-border-color: #bfbfbf;
  --notice-bg-color: #f2f2f2;
  --third-column-bg-color: #fafafa;
  --button-bg-color: #6678ff;
  --search-bg-color: #f2f2f2;
  --text-highlight-color: #000;
  --select-bg-color: #fff;
  --hover-bg-color: #0000000a;
  --active-bg-color: #0000001a;
  --code-hl-default-color: #404040;
  --code-hl-comment-color: #999;
  --code-hl-comment-bg-color: #1e32500d;
  --code-hl-attr-color: gray;
  --code-hl-attribute-color: #236f99;
  --code-hl-keyword-color: #f5475b;
  --code-hl-type-color: #cc4000;
  --code-hl-number-color: #334bff;
  --code-hl-addition-color: #227542;
  --scrollbar-thumb-color: #bfbfbf;
  --scrollbar-hover-color: #999;
  --live-code-preview-frame-color: #ededed;
  --live-code-bg-color: #fafafa;
  --live-code-line-number-bg-color: #f2f2f2;
  --live-code-active-line-bg-color: #e6e6e6;
  --live-code-line-border-bg-color: #d9d9d9;
  --live-code-color: #404040;
  --live-code-caret-color: #1a1a1a;
  --live-code-selection-match-color: #ffc800;
  --live-code-bracket-active-color: #b63243;
  --live-code-tooltip-active-color: #fff;
  --iframe-bg-color: #fff;
  --mark-bg-color: #ffc800;
  --box-shadow: 0px 0 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  background-color: #fff;
}

.color-scheme-dark {
  --primary-color: #001eff;
  --primary-text-color: #ccc;
  --primary-bg-color: #1a1a1a;
  --header-bg-color: #262626;
  --sidebar-bg-color: #262626;
  --settings-bg-color: #4d4d4d;
  --separator-color: #404040;
  --link-text-color: #6678ff;
  --link-hover-color: var(--link-text-color);
  --pre-bg-color: #6e768133;
  --code-text-color: var(--primary-text-color);
  --table-border-color: gray;
  --notice-bg-color: #404040;
  --third-column-bg-color: #333;
  --search-bg-color: #333;
  --text-highlight-color: #fff;
  --select-bg-color: #404040;
  --hover-bg-color: #ffffff0a;
  --active-bg-color: #ffffff1a;
  --code-hl-default-color: #999;
  --code-hl-comment-color: gray;
  --code-hl-comment-bg-color: #5a5a5f66;
  --code-hl-attr-color: #999;
  --code-hl-attribute-color: #59dfe1;
  --code-hl-keyword-color: #f281a7;
  --code-hl-type-color: #f5475b;
  --code-hl-number-color: #6678ff;
  --code-hl-addition-color: #009ea1;
  --scrollbar-thumb-color: #999;
  --scrollbar-hover-color: gray;
  --live-code-preview-frame-color: #0d0d0d;
  --live-code-bg-color: #262626;
  --live-code-line-number-bg-color: #333;
  --live-code-active-line-bg-color: #4d4d4d;
  --live-code-line-border-bg-color: #404040;
  --live-code-color: #f2f2f2;
  --live-code-caret-color: #fafafa;
  --live-code-selection-match-color: #ffc800;
  --live-code-bracket-active-color: #cc4000;
  --live-code-tooltip-active-color: #1a1a1a;
  --iframe-bg-color: #0d0d0d;
  --mark-bg-color: #ffc800;
  --box-shadow: 0px -5px 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  background-color: #1a1a1a;
}

body {
  color: var(--primary-text-color);
  margin: 0;
  padding: 0;
  font-family: Proxima Nova Fin, Arial, Helvetica Nue, Helvetica, sans-serif;
  font-size: 16rem;
  font-weight: 400;
  line-height: 1.5;
}

body[color-scheme="light"] {
  --primary-color: #001eff;
  --primary-text-color: #212121;
  --primary-bg-color: #fff;
  --header-bg-color: #fafafa;
  --sidebar-bg-color: #fafafa;
  --settings-bg-color: #f2f2f2;
  --separator-color: #d9d9d9;
  --link-text-color: var(--primary-color);
  --link-hover-color: var(--link-text-color);
  --pre-bg-color: #afb8c133;
  --code-text-color: var(--primary-text-color);
  --table-border-color: #bfbfbf;
  --notice-bg-color: #f2f2f2;
  --third-column-bg-color: #fafafa;
  --button-bg-color: #6678ff;
  --search-bg-color: #f2f2f2;
  --text-highlight-color: #000;
  --select-bg-color: #fff;
  --hover-bg-color: #0000000a;
  --active-bg-color: #0000001a;
  --code-hl-default-color: #404040;
  --code-hl-comment-color: #999;
  --code-hl-comment-bg-color: #1e32500d;
  --code-hl-attr-color: gray;
  --code-hl-attribute-color: #236f99;
  --code-hl-keyword-color: #f5475b;
  --code-hl-type-color: #cc4000;
  --code-hl-number-color: #334bff;
  --code-hl-addition-color: #227542;
  --scrollbar-thumb-color: #bfbfbf;
  --scrollbar-hover-color: #999;
  --live-code-preview-frame-color: #ededed;
  --live-code-bg-color: #fafafa;
  --live-code-line-number-bg-color: #f2f2f2;
  --live-code-active-line-bg-color: #e6e6e6;
  --live-code-line-border-bg-color: #d9d9d9;
  --live-code-color: #404040;
  --live-code-caret-color: #1a1a1a;
  --live-code-selection-match-color: #ffc800;
  --live-code-bracket-active-color: #b63243;
  --live-code-tooltip-active-color: #fff;
  --iframe-bg-color: #fff;
  --mark-bg-color: #ffc800;
  --box-shadow: 0px 0 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  background-color: #fff;
}

body[color-scheme="light"] pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

body[color-scheme="light"] code.hljs {
  padding: 3px 5px;
}

body[color-scheme="light"] .hljs {
  color: #383a42;
  background: #fafafa;
}

body[color-scheme="light"] .hljs-comment, body[color-scheme="light"] .hljs-quote {
  color: #a0a1a7;
  font-style: italic;
}

body[color-scheme="light"] .hljs-doctag, body[color-scheme="light"] .hljs-formula, body[color-scheme="light"] .hljs-keyword {
  color: #a626a4;
}

body[color-scheme="light"] .hljs-deletion, body[color-scheme="light"] .hljs-name, body[color-scheme="light"] .hljs-section, body[color-scheme="light"] .hljs-selector-tag, body[color-scheme="light"] .hljs-subst {
  color: #e45649;
}

body[color-scheme="light"] .hljs-literal {
  color: #0184bb;
}

body[color-scheme="light"] .hljs-addition, body[color-scheme="light"] .hljs-attribute, body[color-scheme="light"] .hljs-meta .hljs-string, body[color-scheme="light"] .hljs-regexp, body[color-scheme="light"] .hljs-string {
  color: #50a14f;
}

body[color-scheme="light"] .hljs-attr, body[color-scheme="light"] .hljs-number, body[color-scheme="light"] .hljs-selector-attr, body[color-scheme="light"] .hljs-selector-class, body[color-scheme="light"] .hljs-selector-pseudo, body[color-scheme="light"] .hljs-template-variable, body[color-scheme="light"] .hljs-type, body[color-scheme="light"] .hljs-variable {
  color: #986801;
}

body[color-scheme="light"] .hljs-bullet, body[color-scheme="light"] .hljs-link, body[color-scheme="light"] .hljs-meta, body[color-scheme="light"] .hljs-selector-id, body[color-scheme="light"] .hljs-symbol, body[color-scheme="light"] .hljs-title {
  color: #4078f2;
}

body[color-scheme="light"] .hljs-built_in, body[color-scheme="light"] .hljs-class .hljs-title, body[color-scheme="light"] .hljs-title.class_ {
  color: #c18401;
}

body[color-scheme="light"] .hljs-emphasis {
  font-style: italic;
}

body[color-scheme="light"] .hljs-strong {
  font-weight: 700;
}

body[color-scheme="light"] .hljs-link {
  text-decoration: underline;
}

body[color-scheme="dark"] {
  --primary-color: #001eff;
  --primary-text-color: #ccc;
  --primary-bg-color: #1a1a1a;
  --header-bg-color: #262626;
  --sidebar-bg-color: #262626;
  --settings-bg-color: #4d4d4d;
  --separator-color: #404040;
  --link-text-color: #6678ff;
  --link-hover-color: var(--link-text-color);
  --pre-bg-color: #6e768133;
  --code-text-color: var(--primary-text-color);
  --table-border-color: gray;
  --notice-bg-color: #404040;
  --third-column-bg-color: #333;
  --search-bg-color: #333;
  --text-highlight-color: #fff;
  --select-bg-color: #404040;
  --hover-bg-color: #ffffff0a;
  --active-bg-color: #ffffff1a;
  --code-hl-default-color: #999;
  --code-hl-comment-color: gray;
  --code-hl-comment-bg-color: #5a5a5f66;
  --code-hl-attr-color: #999;
  --code-hl-attribute-color: #59dfe1;
  --code-hl-keyword-color: #f281a7;
  --code-hl-type-color: #f5475b;
  --code-hl-number-color: #6678ff;
  --code-hl-addition-color: #009ea1;
  --scrollbar-thumb-color: #999;
  --scrollbar-hover-color: gray;
  --live-code-preview-frame-color: #0d0d0d;
  --live-code-bg-color: #262626;
  --live-code-line-number-bg-color: #333;
  --live-code-active-line-bg-color: #4d4d4d;
  --live-code-line-border-bg-color: #404040;
  --live-code-color: #f2f2f2;
  --live-code-caret-color: #fafafa;
  --live-code-selection-match-color: #ffc800;
  --live-code-bracket-active-color: #cc4000;
  --live-code-tooltip-active-color: #1a1a1a;
  --iframe-bg-color: #0d0d0d;
  --mark-bg-color: #ffc800;
  --box-shadow: 0px -5px 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  background-color: #1a1a1a;
}

body[color-scheme="dark"] pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

body[color-scheme="dark"] code.hljs {
  padding: 3px 5px;
}

body[color-scheme="dark"] .hljs {
  color: #abb2bf;
  background: #282c34;
}

body[color-scheme="dark"] .hljs-comment, body[color-scheme="dark"] .hljs-quote {
  color: #5c6370;
  font-style: italic;
}

body[color-scheme="dark"] .hljs-doctag, body[color-scheme="dark"] .hljs-formula, body[color-scheme="dark"] .hljs-keyword {
  color: #c678dd;
}

body[color-scheme="dark"] .hljs-deletion, body[color-scheme="dark"] .hljs-name, body[color-scheme="dark"] .hljs-section, body[color-scheme="dark"] .hljs-selector-tag, body[color-scheme="dark"] .hljs-subst {
  color: #e06c75;
}

body[color-scheme="dark"] .hljs-literal {
  color: #56b6c2;
}

body[color-scheme="dark"] .hljs-addition, body[color-scheme="dark"] .hljs-attribute, body[color-scheme="dark"] .hljs-meta .hljs-string, body[color-scheme="dark"] .hljs-regexp, body[color-scheme="dark"] .hljs-string {
  color: #98c379;
}

body[color-scheme="dark"] .hljs-attr, body[color-scheme="dark"] .hljs-number, body[color-scheme="dark"] .hljs-selector-attr, body[color-scheme="dark"] .hljs-selector-class, body[color-scheme="dark"] .hljs-selector-pseudo, body[color-scheme="dark"] .hljs-template-variable, body[color-scheme="dark"] .hljs-type, body[color-scheme="dark"] .hljs-variable {
  color: #d19a66;
}

body[color-scheme="dark"] .hljs-bullet, body[color-scheme="dark"] .hljs-link, body[color-scheme="dark"] .hljs-meta, body[color-scheme="dark"] .hljs-selector-id, body[color-scheme="dark"] .hljs-symbol, body[color-scheme="dark"] .hljs-title {
  color: #61aeee;
}

body[color-scheme="dark"] .hljs-built_in, body[color-scheme="dark"] .hljs-class .hljs-title, body[color-scheme="dark"] .hljs-title.class_ {
  color: #e6c07b;
}

body[color-scheme="dark"] .hljs-emphasis {
  font-style: italic;
}

body[color-scheme="dark"] .hljs-strong {
  font-weight: 700;
}

body[color-scheme="dark"] .hljs-link {
  text-decoration: underline;
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #fff;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #1a1a1a;
  }
}

@media print {
  html, body {
    height: auto;
    overflow: auto;
  }

  body {
    --primary-color: #001eff;
    --primary-text-color: #212121;
    --primary-bg-color: #fff;
    --header-bg-color: #fafafa;
    --sidebar-bg-color: #fafafa;
    --settings-bg-color: #f2f2f2;
    --separator-color: #d9d9d9;
    --link-text-color: var(--primary-color);
    --link-hover-color: var(--link-text-color);
    --pre-bg-color: #afb8c133;
    --code-text-color: var(--primary-text-color);
    --table-border-color: #bfbfbf;
    --notice-bg-color: #f2f2f2;
    --third-column-bg-color: #fafafa;
    --button-bg-color: #6678ff;
    --search-bg-color: #f2f2f2;
    --text-highlight-color: #000;
    --select-bg-color: #fff;
    --hover-bg-color: #0000000a;
    --active-bg-color: #0000001a;
    --code-hl-default-color: #404040;
    --code-hl-comment-color: #999;
    --code-hl-comment-bg-color: #1e32500d;
    --code-hl-attr-color: gray;
    --code-hl-attribute-color: #236f99;
    --code-hl-keyword-color: #f5475b;
    --code-hl-type-color: #cc4000;
    --code-hl-number-color: #334bff;
    --code-hl-addition-color: #227542;
    --scrollbar-thumb-color: #bfbfbf;
    --scrollbar-hover-color: #999;
    --live-code-preview-frame-color: #ededed;
    --live-code-bg-color: #fafafa;
    --live-code-line-number-bg-color: #f2f2f2;
    --live-code-active-line-bg-color: #e6e6e6;
    --live-code-line-border-bg-color: #d9d9d9;
    --live-code-color: #404040;
    --live-code-caret-color: #1a1a1a;
    --live-code-selection-match-color: #ffc800;
    --live-code-bracket-active-color: #b63243;
    --live-code-tooltip-active-color: #fff;
    --iframe-bg-color: #fff;
    --mark-bg-color: #ffc800;
    --box-shadow: 0px 0 5px -3px #0003, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
    background-color: #fff;
    overflow: auto;
  }

  header, #sidebar, #results-container {
    display: none;
  }

  main {
    height: auto;
    min-height: auto;
    margin-top: 0;
    overflow: auto;
  }

  #page .content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

/*# sourceMappingURL=index.a7682b78.css.map */
