.backdrop-overlay {
  display: none;
  visibility: hidden;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  transition: opacity 200ms ease-out 0ms, visibility 0ms linear 200ms;

  &.is-visible, &.desktop-visible {
    display: block;
    visibility: visible;
  }

  &.desktop-visible {
    z-index: 1100; // on top of sidebar ( used when open settings menu )
  }

  .media-desktop({
    display: none;

    &.is-visible {
      display: none;
    }
  });
}
