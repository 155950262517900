@media print {
  html, body {
    height: auto;
    overflow: auto;
  }

  body {
    .color-scheme-light;
  }

  body { overflow: auto; }
  header, #sidebar, #results-container { display: none; }
  main {
    height: auto;
    min-height: auto;
    overflow: auto;
    margin-top: 0;
  }
  #page {
    .content {
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
