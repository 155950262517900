@import '../variables';
@import '../mixins/responsive.less';

@search-input-width: 250px;

.search-container {
  display: flex;
  pointer-events: none;
  visibility: hidden;
  position: fixed;
  height: @header-height;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 0.1s 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  background: @color-white;

  .search-input {
    position: relative;
    display: flex;
    outline: none;
    border: none;
    background-color: transparent;
    flex: 1;
    height: 100%;
    width: 100%;
    top: 16px;
    padding: 0 100px 0 @space-lg;
    font-size: @fs-h6;
    color: @color-eerie-black;
    transition: top 0.3s 0s cubic-bezier(0.4, 0, 0.2, 1);
    
    .media-desktop({
      padding-left: 50px;
      padding-right: 150px;
    });
  }

  &.is-visible {
    opacity: 1;
    pointer-events: all;
    visibility: visible;

    .search-input {
      top: 0;
    }
  }

  .media-desktop-extra({
    padding: 0 calc((100% - @container-max-width) / 2);
  });
}

.search-button {
  color: var(--primary-text-color);
  cursor: pointer;
  z-index: 2;

  &.close-button {
    color: @color-eerie-black;
    &::after {
      content: "\00d7";
      font-size: 28rem;
      font-family: serif;
      font-weight: 300;
    }
    svg {
      display: none;
    }
  }

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}

.results-container {
  position: fixed;
  overflow: auto;
  z-index: 2; // should be 2 because content z-index is 1
  transition: transform 0.2s ease-in-out;
  transform: translateY(-100%);
  height: calc(100% - @header-height);
  top: 0;
  left: 0;
  right: 0;
  padding: 0 @space-md;
  padding-bottom: @space-lg;
  background-color: var(--primary-bg-color);

  a {
    color: var(--primary-text-color);
  }

  p {
    word-break: break-all;
  }

  mark {
    color: var(--text-highlight-color);
    font-weight: 500;
    background-color: transparent;
  }

  &.is-visible {
    transform: translateY(@header-height);
  }

  .media-desktop({
    margin-left: @sidebar-width;
    padding: 0 @space-xl;
  });

  .media-desktop-extra({
    margin-left: calc((100% - @container-max-width) / 2 + @sidebar-width);
  });
}

.item-container {
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin-bottom: @space-xl;

  h4 {
    position: relative;
    padding-left: @space-sm;
    &::before {
      content: "";
      position: absolute;
      height: 100%;
      left: 0;
      width: 2px;
      background-color: var(--code-hl-number-color);
    }
  }

  mark {
    padding: 0;
  }

  &:hover {
    opacity: 0.9;
  }
}

.results-placeholder {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  opacity: 0.8;
  max-width: @code-content-max-width;

  img {
    width: 150px;
    height: auto;
  }

  &.is-visible {
    display: flex;
  }
}

.results-body {
  max-width: @code-content-max-width;
}
.results-errorMsg {
  word-break: break-all;
}
