@import '../variables';
@import '../color';

aside {
  --notice-notice-color: @color-blue-ribbon;
  --notice-warning-color: @color-supernova;
  --notice-important-color: @color-persian-green;
  --notice-success-color: @color-emerald;
  --notice-fail-color: @color-chestnut-rose;

  position: relative;
  display: block;
  margin: 30px 0;
  padding: 15px 15px 15px 40px;
  border-left: 4px solid;
  border-radius: 3px;
  background-color: var(--notice-bg-color);

  &::before {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    left: 8px;
    top: 15px;
  }

  &.info {
    border-color: var(--notice-notice-color);

    &::before {
      content: data-uri('../../assets/images/icons/info-icon.svg');
    }
  }

  &.warning {
    border-color: var(--notice-warning-color);

    &::before {
      content: data-uri('../../assets/images/icons/alert-icon.svg');
    }
  }

  &.important {
    border-color: var(--notice-important-color);

    &::before {
      content: data-uri('../../assets/images/icons/warning-circle-icon.svg');
    }
  }
  
  &.success {
    border-color: var(--notice-success-color);

    &::before {
      content: data-uri('../../assets/images/icons/tick-circle-icon.svg');
    }
  }

  &.fail {
    border-color: var(--notice-fail-color);

    &::before {
      content: data-uri('../../assets/images/icons/cross-circle-icon.svg');
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
