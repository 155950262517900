@import '../variables';

ol,
ul {
  padding-left: @space-md;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: @space-md;
  list-style-position: inside;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}