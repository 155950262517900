@import '../variables';

[type="checkbox"] {
  padding: 0;
}

[type="checkbox"] {
  &:not(:checked), &:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  + span {
    position: relative;
    padding-left: 25rem;
    cursor: pointer;
    display: inline-block;
    user-select: none;
    height: 20rem;
    line-height: 20rem;
    font-size: @fs-base;
  
    &::before, &::after {
      position: absolute;
      content: '';
      left: 0;
      z-index: 0;
    }

    &::after {
      top: 1rem;
      left: 2rem;
      z-index: 0;
      height: 16rem;
      width: 16rem;
      background-color: transparent;
      border: 1rem solid var(--primary-text-color);
    }
    &::before {
      top: 2rem;
      left: 8rem;
      width: 0;
      height: 0;
      z-index: 1;
      border-radius: 1rem;
      transform: rotateZ(37deg);
      border: 2rem solid var(--primary-text-color);
    }
  }

  &:not(:checked) {
    + span {
      &::before {
        border: 3rem solid transparent;
      }
    }
  }

  &:checked {
    + span {
      &::before {
        width: 5rem;
        height: 10rem;
        border-top: 2rem solid transparent;
        border-left: 2rem solid transparent;
        border-right: 2rem solid var(--primary-text-color);
        border-bottom: 2rem solid var(--primary-text-color);
      }
    }
  }
}