@import '../variables';
@import '../mixins/responsive.less';

@setting-menu-width: 350px;

.settings-content {
  position: fixed;
  transform: translateX(@setting-menu-width);
  transition: all 200ms ease-in-out;
  right: 0;
  top: 0;
  bottom: 0;
  width: @setting-menu-width;
  background-color: var(--sidebar-bg-color);
  z-index: 1100;
  visibility: hidden;

  .media-desktop({
    transform: translateX(@setting-menu-width + 50);
    width: @setting-menu-width + 50;
  });

  &.is-visible {
    visibility: visible;
    transform: translateX(0);
  }
}

.settings-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: @header-height;
  min-height: @header-height + 1;
  padding-right: @space-xs;
  border-bottom: 1px solid var(--separator-color);

  .media-desktop({
    padding-right: @space-xl;
  });

  .right-arrow {
    transform: rotate(45deg);
  }
}

.settings-button {
  color: var(--primary-text-color);

  &::after {
    content: '\22EE';
    font-weight: bold;
    font-size: 26px;
  }
}

.settings-body {
  padding: @space-xl @space-md;

  .media-desktop({
    padding: @space-xl;
  });
}

.settings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: @space-xl;
}

.theme-toggle {
  width: auto;
  height: auto;
  cursor: pointer;

  input {
    display: none;
  }

  input[type=checkbox]:checked + .switch {
    background-color: @color-tundora;
  }

  input[type=checkbox]:checked + .switch::before {
    transform: translateX(20px);
    background-color: @color-cod-grey;
  }

  .switch {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border-radius: 16px;
    width: 44px;
    height: 24px;
    transition: background 0.25s;
    background: @color-alto;

    &::before, &::after {
      content: "";
    }

    &::before {
      display: block;
      position: absolute;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      width: 18px;
      height: 18px;
      top: 3px;
      left: 3px;
      transition: transform 0.25s;
      background-color: @color-white;
    }
  }
}
