.dropdown {
  position: relative;
  margin: 0 @space-md;
  &:hover {
    .dropdown-content {
      display: block;
    }
    .dropdown-btn {
      color: var(--text-highlight-color);
    }
  }
}

.dropdown-btn {
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: 500;
  height: 100%;
  color: var(--primary-text-color);
}

.dropdown-arrow {
  cursor: pointer;
  border: solid;
  border-width: 0 2px 2px 0;
  padding: 2.5px;
  margin-left: 10px;
  margin-bottom: 2px;
  transform: rotate(45deg);
}

.dropdown-content {
  display: none;
  position: fixed;
  top: 50px;
  min-width: 160px;
  background-color: var(--sidebar-bg-color);
  box-shadow: var(--box-shadow);

  a {
    padding: @space-sm @space-md;
    text-align: left;
  }
}
