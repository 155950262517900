@import '../variables.less';

// ===========================================
// Responsive Mixins
// ===========================================
.media-desktop(@rules) {
	@media (min-width: @screen-desktop) {
		@rules();
	}
}

.media-desktop-plus(@rules) {
	@media (min-width: @screen-desktop-plus) {
		@rules();
	}
}

.media-desktop-extra(@rules) {
	@media (min-width: @container-max-width) {
		@rules();
	}
}
