@font-face {
  font-family: 'Proxima Nova Fin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-regular.woff2') format('woff2'),
  url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Fin';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-semibold.woff2') format('woff2'),
  url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-semibold.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova Fin';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-bold.woff2') format('woff2'),
  url('https://cdn.refinitiv.net/public/libs/elf/assets/elf-theme-halo/resources/fonts/proximanovafin/proximanovafin-bold.woff') format('woff');
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('../assets/fonts/source-code-pro/sourcecodepro-bold-webfont.woff2') format('woff2'),
       url('../assets/fonts/source-code-pro/sourcecodepro-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Source Code Pro';
  src: url('../assets/fonts/source-code-pro/sourcecodepro-light-webfont.woff2') format('woff2'),
       url('../assets/fonts/source-code-pro/sourcecodepro-light-webfont.woff') format('woff');
  font-weight: lighter;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('../assets/fonts/source-code-pro/sourcecodepro-regular-webfont.woff2') format('woff2'),
       url('../assets/fonts/source-code-pro/sourcecodepro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
