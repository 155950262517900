@import '../variables';
@import '../color';

.action-button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1px;
  border-radius: 50%;
  width: @button-size;
  height: @button-size;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: var(--hover-bg-color);
  }

  &:active {
    background-color: var(--active-bg-color)
  }
}
