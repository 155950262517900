@import '../variables';
@import '../mixins/responsive.less';

.lang-selector {
  width: 100%;
  background-color: var(--primary-bg-color);

  .lang-item, .preview-btn {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: var(--primary-text-color);
    font-weight: 500;
    padding: @space-sm @space-md;

    &:hover {
      opacity: 0.8;
    }
  }
}

.lang-global-selector {
  position: fixed;
  z-index: 1;
  width: @code-content-max-width / 2;

  .lang-item {
    &.active {
      background-color: var(--third-column-bg-color);
    }
  }
}

.lang-content-selector {
  .lang-item {
    &.active {
      background-color: var(--pre-bg-color);
    }

    .media-desktop({
      &.desktop-active {
        background-color: var(--pre-bg-color);
      }
    });
  }
}

.pre-block {
  border: 1px solid var(--separator-color);
  margin-bottom: @space-md;
  border-radius: 3px;
  clear: none;
  pre {
    display: none;
    margin: 0;

    &.active {
      display: block;
    }
  }
}
